<template>
  <div>
    <auth-container>
      <h1
        data-testid="login-title"
        class=" mb-12 text-center font-semibold  text-black"
      >
        {{ $t("login") }}
      </h1>
      <input-text
        data-testid="login-email"
        class="mb-5 w-96"
        :name="'email'"
        :id="'email'"
        v-model="state.user.email"
        :label="$t('email')"
        :labelClass="'bg-white'"
        @keyup.enter="LOGIN(state.user)"
      />
      <input-text
        data-testid="login-password"
        class="mb-5 w-96"
        :name="'password'"
        :id="'password'"
        v-model="state.user.password"
        :label="$t('password')"
        :labelClass="'bg-white'"
        :type="'password'"
        @keyup.enter="LOGIN(state.user)"
      />
      <input-checkbox
        data-testid="check-stay-logged-in"
        class="mb-5 w-96"
        :name="'stay_logged_in'"
        :id="'stay_logged_in'"
        v-model="state.user.stay_logged_in"
        :label="$t('stay_logged_in')"
        :defaultColor="'fontgrey'"
        :activeColor="'primary'"
        @change="state.user.stay_logged_in = !state.user.stay_logged_in"
      />
      <custom-button
        data-testid="login-button"
        :id="'login-button'"
        @click="LOGIN(state.user)"
        class="w-96 h-11"
        :text="$t('sign_in')"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
      />

      <div
        class="text-red text-center mt-8 text-sm"
        v-if="state.wrong_password == true"
      >
        {{ $t("wrong_password_and_email") }} <br />
        {{ $t("try_again") }}
        "{{ $t("forgot_password") }}".
      </div>
      <router-link
        tag="h4"
        :to="{ name: 'ResetPassword' }"
        class="absolute bottom-5 cursor-pointer text-grey-fontgrey transition-colors hover:text-grey-dark"
      >
        {{ $t("password_forgot") }}
      </router-link>
    </auth-container>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import CustomButton from "../../components/input/CustomButton.vue";
import InputCheckbox from "../../components/input/InputCheckbox.vue";
import InputText from "../../components/input/InputText.vue";
import { AUTH_TYPES, HELPERS_TYPES, STORE_TYPES } from "../../store/types";

import { useStore } from "vuex";
import AuthContainer from "./helpers/AuthContainer.vue";
export default defineComponent({
  name: "Login",
  components: { InputText, InputCheckbox, CustomButton, AuthContainer },
  setup() {
    const store = useStore();
    const state = reactive({
      user: {
        email: "",
        password: "",
        stay_logged_in: false,
      },
      wrong_password: false,
    });

    /** Computeds */

    /**
     * functions
     */
    async function LOGIN(user) {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "login-button"
      );
      const response = await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.LOGIN}`,
        {
          user,
        }
      );
      if (response == false) {
        state.wrong_password = true;
      }

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "login-button"
      );
    }

    return {
      state,
      LOGIN,
    };
  },
});
</script>

<style></style>
